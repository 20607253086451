import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import raterRoutes from './rater'
import commissionsRoutes from './commissions'

const routes = [
  {
    path: '/',
    redirect: { name: 'commissions' },
  },
  ...raterRoutes,
  ...commissionsRoutes,
  {
    path: '/login',
    name: 'auth.login',
    component: () => import('@/pages/auth/login/Index.vue'),
    meta: {
      layout: 'blank',
      redirectIfLoggedIn: true,
      skipAuth: true,
    },
  },
  {
    path: '/embed/:component',
    name: 'embed',
    component: () => import('@/pages/embed/Index.vue'),
    meta: {
      layout: 'blank',
      skipAuth: false,
    },
  },
  {
    path: '/error/404',
    name: 'error404',
    component: () => import('@/pages/errors/Error404.vue'),
    meta: {
      layout: 'blank',
      skipAuth: true,
    },
  },
  //TODO:: REMOVE THIS TEMPOARY ROUTE AFTER EMBED IS UPDATED
  {
    path: '/configuration/commissions/commissions-plans',
    component: () => import('@/pages/commissions/commission-plans/Index.vue'),
    meta:{
      layout: 'content',
    }
  },
  {
    path: '*',
    name: '404',
    redirect: '/error/404',
    meta: {
      skipAuth: true,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ left: 0, top: 0 })
      }, 500)
    })
  },
})

export default router
