import auth from './auth'
import carriers from './carriers'
import commissionMonthlyReview from './commissionMonthlyReview'
import commissionPlans from './commissionPlans'
import commissions from './commissions'
import commissionSetup from './commissionSetup'
import commissionStatements from './commissionStatements'
import commissionsAggregatorCarriers from './commissionsAggregatorCarriers'
import contacts from './contacts'
import policies from './policies'
import policyTypes from './policyTypes'
import rater from './rater'

import autos from './autos'
import users from './users'

import enums from './enums'

export default {
  auth,
  rater,
  contacts,
  carriers,
  policies,
  policyTypes,
  commissions,
  commissionStatements,
  commissionPlans,
  commissionSetup,
  commissionMonthlyReview,
  commissionsAggregatorCarriers,
  users,
  autos,
  enums,
}
