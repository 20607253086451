import api from '@/api'

export default {
  namespaced: true,
  state: {
    users: [],
    usersLoaded: false,
  },
  getters: {
    getUsers(state) {
      return state.users
    },
    getUsersLoaded(state) {
      return state.usersLoaded
    },
  },
  mutations: {
    setUsers(state, users) {
      state.users = users
      state.usersLoaded = true
    },
  },
  actions: {
    loadUsers({ commit }) {
      return new Promise((resolve, reject) => {
        api.users.index().then(({ data }) => {
          commit('setUsers', data)
          resolve(data)
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
}
