import api from '@/api'

export default {
  namespaced: true,
  state: {
    enums: {},
    enumsLoaded: false,
  },
  getters: {
    getEnums(state) {
      return state.enums
    },
    getEnumsLoaded(state) {
      return state.enumsLoaded
    }
  },
  mutations: {
    setEnums(state, enums) {
      state.enums = enums
      state.enumsLoaded = true
    },
  },
  actions: {
    loadEnums({ commit }) {
      return api.enums.index().then(({ data }) => {
        commit('setEnums', data)
      })
    },
  },
}
