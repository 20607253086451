import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import _ from 'lodash'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import '@/config/3rdparty/pendo'
import store from './config/store'
import api from './api'
import moment from 'moment'
import axios from 'axios'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import * as LDClient from 'launchdarkly-js-client-sdk'
import appDebugger from '@/config/globals/debugger'
import tracking from '@/config/globals/tracking'
import colors from 'vuetify/lib/util/colors'
import FeatureFlagsMixin from './config/mixins/FeatureFlags'
import SendDocumentHeightOnLoadMixin from './config/mixins/SendDocumentHeightOnLoad'
import router from './config/routes'

//START:: these lines must be before all and in this order
if (sessionStorage.getItem('embedded')) {
  Vue.prototype.$isEmbedded = true
} else {
  let urlParams = new URLSearchParams(window.location.search)
  Vue.prototype.$isEmbedded = urlParams.has('embedded')

  if (Vue.prototype.$isEmbedded) {
    sessionStorage.setItem('embedded', true)
  }
}

//END:: these lines must be before all and in this order

axios.interceptors.response.use(response => {
  if (_.get(response, 'data.data', false)) {
    return response.data
  }

  return response
})

function mountApp() {
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
  }).$mount('#app')
}

Vue.prototype.$featureFlags = false

Vue.config.productionTip = false

Vue.prototype.$api = api

Vue.prototype.$_ = _

Vue.prototype.$colors = colors

Vue.prototype.sendDocumentHeightToParent = function() {
  // No need to send the size anymore ...
}

/*
Vue.prototype.sendDocumentHeightToParent = _.debounce(function() {
  if (Vue.prototype.$isEmbedded === false) {
    return
  }

  setTimeout(() => {
    let message = {
      event: 'embedResized',
      size: document.getElementById('bottom-identifier').getBoundingClientRect().top + 'px',
    }

    this.$debug.info('Sending resize to parent window: ', message)

    window.top.postMessage(JSON.stringify(message), '*')
  }, 100)
}, 100)
*/

Vue.prototype.$moment = moment

appDebugger.init()

Vue.prototype.$tracking = tracking

Vue.mixin(FeatureFlagsMixin)

Vue.mixin(SendDocumentHeightOnLoadMixin)

/**
 * TODO:: add a query string redirect when session expires so we can send them back
 * to the page they were on
 */
store.dispatch('loadUserFromToken').then(() => {
  // handle authentication checks && feature flags
  router.beforeEach((to, from, next) => {
    if (to.meta.skipAuth === true) {
      return next()
    }

    if (to.meta.redirectIfLoggedIn !== true && store.getters.isUserLoggedIn === false) {
      return next({ name: 'auth.login' })
    }

    if (Vue.prototype.$featureFlags === false) {
      let FeatureFlagUser = {
        key: store.getters.getCurrentUser.attributes.legacy_id,
        email: store.getters.getCurrentUser.attributes.email,
        custom: {
          legacy_user_id: store.getters.getCurrentUser?.attributes?.legacy_id,
          legacy_agency_id: store.getters.getCurrentUser?.related?.agency?.data?.attributes?.legacy_id,
          created: store.getters.getCurrentUser?.attributes?.created,
        },
      }

      Vue.prototype.$featureFlags = LDClient.initialize(process.env.VUE_APP_LAUNCH_DARKLY_KEY, FeatureFlagUser)

      Vue.prototype.$featureFlags.on('ready', () => {
        return next()
      })
    } else {
      return next()
    }
  })

  //load commission setup
  router.beforeEach((to, from, next) => {
    if (to.meta.requireCommissionSetup) {
      if (store.getters['commissions/getCommissionSetupLoaded'] === false) {
        store
          .dispatch('commissions/loadCommissionSetup')
          .then(response => {
            if (store.getters['commissions/getCommissionSetup'].completed === true) {
              return next()
            }

            if (router.currentRoute.path.startsWith('commissions/settings') === false) {
              next({ name: 'commission-settings' })
            }
          })
          .catch(error => {
          })
      } else {
        if (store.getters['commissions/getCommissionSetup'].completed === true) {
          return next()
        }

        if (router.currentRoute.path.startsWith('commissions/settings') === false) {
          return next({ name: 'commission-settings' })
        }

        return next()
      }
    } else {
      return next()
    }
  })

  router.beforeEach((to, from, next) => {
    if (Vue.prototype.$isEmbedded) {
      if (to.meta.legacyRoute) {
        let legacyPath = to.meta.legacyRoute
        _.forEach(to.params, (value, key) => {
          legacyPath = legacyPath.replace(`:${key}`, value)
        })

        let tempQuery = _.cloneDeep(to.query)

        delete tempQuery.embedToken
        delete tempQuery.embedded
        delete tempQuery.legacyUri

        let queryString = Object.keys(tempQuery)
          .map(function(key) {
            return key + '=' + tempQuery[key]
          })
          .join('&')

        let newPath = legacyPath + '?' + queryString
        //let newLocation = process.env.VUE_APP_LEGACY_HOST_URL + newPath
        let newLocation = newPath

        /**
         * if same path but different query, we will just re-write the url instead of sending window to new location
         */
          //if (to.name === from.name) {
        let message = {
            event: 'pathChange',
            path: newLocation,
          }

        Vue.prototype.$debug.info('Sending path change to parent window: ', message)

        window.top.postMessage(JSON.stringify(message), '*')
        next()
        /*} else {
          if (to.query.legacyUri !== newPath) {
            console.log('not matched', {
              newPath: newPath,
              legacyUri: to.query.legacyUri,
            })
            window.top.location.href = newLocation
          } else {
            return next()
          }
        }*/
      } else {
        return next()
      }
    } else {
      return next()
    }
  })

  //load enums for global
  router.beforeEach((to, from, next) => {
    if (store.getters['enums/getEnumsLoaded'] === false) {
      store
        .dispatch('enums/loadEnums')
        .then(response => {
          return next()
        })
        .catch(error => {
          return next()
        })
    } else {
      return next()
    }
  })

  mountApp()
})
