<template>
  <component
    :is="resolveLayoutVariant"
    :class="`skin-variant--${appSkinVariant}`"
  >
    <transition
      :name="appRouteTransition"
      mode="out-in"
      appear
    >
      <router-view></router-view>
    </transition>

    <pendo-button v-if="false"/>
  </component>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {computed} from '@vue/composition-api'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
import {useRouter} from '@core/utils'
import {useLayout} from '@core/layouts/composable/useLayout'

// Layouts
import LayoutContentVerticalNav
  from '@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import LayoutContentHorizontalNav
  from '@/layouts/variants/content/horizontal-nav/LayoutContentHorizontalNav.vue'
import LayoutBlank from '@/layouts/variants/blank/LayoutBlank.vue'
import LayoutEmbedded from '@/layouts/variants/embed/LayoutEmbed.vue';

// Dynamic vh
import useDynamicVh from '@core/utils/useDynamicVh'
import Vue from "vue";
import PendoButton from "@/components/PendoButton";

export default {
  components: {
    PendoButton,
    LayoutContentVerticalNav,
    LayoutContentHorizontalNav,
    LayoutBlank,
    LayoutEmbedded
  },
  setup() {
    const {route} = useRouter()
    const {appContentLayoutNav, appSkinVariant, appRouteTransition} = useAppConfig()

    const {handleBreakpointLayoutSwitch} = useLayout()
    handleBreakpointLayoutSwitch()

    const resolveLayoutVariant = computed(() => {


      let isEmbedded = false;

      if (sessionStorage.getItem('embedded')) {
        isEmbedded = true;
      } else {
        let urlParams = new URLSearchParams(window.location.search);
        isEmbedded = urlParams.has('embedded');
      }

      if (isEmbedded) {
        (new ResizeObserver(() => {
          Vue.prototype.sendDocumentHeightToParent();
        })).observe(document.body);


        return 'layout-embedded';
      }

      if (route.value.meta.layout === 'blank') return 'layout-blank'
      if (route.value.meta.layout === 'embedded') return 'layout-embedded'
      if (route.value.meta.layout === 'content') return `layout-content-${appContentLayoutNav.value}-nav`

      return null
    })

    useDynamicVh()

    return {
      resolveLayoutVariant,
      appSkinVariant,
      appRouteTransition,
    }
  },
  mounted() {
    if (this.$isEmbedded) {
      setInterval(() => {
        this.sendDocumentHeightToParent();
      }, 2000);
    }
  }
}
</script>
<style scoped>

</style>
